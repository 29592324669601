import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const CookiePolicy = props => (
  <Layout {...props}>
    <Seo title='Cookie Policy' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Cookie Policy' />
        <Divider />
        <h3>Introduction</h3>
        <p>
          When you visit or access the 90MIN websites and mobile device software
          applications(including, without limitation, if you open an email we
          have delivered to you), which include, without limitation,{' '}
          <span style={{ fontWeight: 'bold' }}>Football-today.com</span> and any
          other product or service that links to this policy (together, the
          “Service”), we may use (and authorize third parties to use) web
          beacons, cookies, pixel tags, scripts, tags, API and other
          technologies ("Tracking Technologies"). This is explained in this
          policy (“Cookie Policy”) which is a part of our Privacy Policy.
        </p>
        <p>
          Tracking Technologies allow us to automatically collect information
          about you and your online behavior in order to enhance your navigation
          on our Service, improve our Service performance and customize your
          experience on it. We also use this information to collect statistics
          about the usage of our Service, perform analytics, serve ads, and to
          administer services to our users, customers and partners.
        </p>
        <h3>What Are Cookies?</h3>
        <p>
          Cookies are a commonly used tracking technology, comprised of small
          text files (composed only of letters and numbers) that a web server
          places on your computer or mobile device when you visit a webpage.
          When used, the cookie can help make our Services more user friendly,
          for example by remembering your language preferences and settings. You
          can find more information about cookies at www.allaboutcookies.org.
        </p>
        <p>
          Cookies are widely used in order to make websites work in an efficient
          way. The use of cookies allows you to navigate between pages
          efficiently. Cookies remember your preferences, and make the
          interaction between you and the Services smoother and more efficient.
        </p>
        <h3>Why Use Tracking Technologies?</h3>
        <p>There are five main purposes for using Tracking Technologies:</p>
        <p>
          <li style={{ marginLeft: '15px' }}>
            <h4>Strictly necessary Tracking Technologies</h4>
            <p>
              These Tracking Technologies are essential to enable you to login,
              navigate around and use the features of our Services, or to
              provide a service requested by you (such as your username). These
              Tracking Technologies can be used for security and integrity
              reasons for example to detect violation of our policies and for
              support or security features.
            </p>
          </li>
          <li style={{ marginLeft: '15px' }}>
            <h4>Functionality Tracking Technologies</h4>
            <p>
              These Tracking Technologies allow our Services to remember choices
              you make (such as your language) and provide enhanced and
              personalized features. For example, these Tracking Technologies
              are used for authentication (to remember when you are logged in)
              and support other features of our Services.
            </p>
          </li>
          <li style={{ marginLeft: '15px' }}>
            <h4>Performance Tracking Technologies</h4>
            <p>
              These Tracking Technologies collect information about your online
              activity (for example the duration of your visit on our Services),
              including behavioral data and content engagement metrics. These
              Tracking Technologies are used for analytics, research and to
              perform statistics (based on aggregated information).
            </p>
          </li>
          <li style={{ marginLeft: '15px' }}>
            <h4>Marketing or Advertising Tracking Technologies</h4>
            <p>
              These Tracking Technologies are used to deliver tailored offers
              and advertisements to you, based on your derived interests, as
              well as to perform email marketing campaigns. They can also be
              used to limit the number of times you see an advertisement as well
              as help measure the effectiveness of the advertising campaign.
              They are usually placed by our advertising partners and provide
              them insights about the people who see and interact with their
              ads, visit their websites or use their app.
            </p>
          </li>
          <li style={{ marginLeft: '15px' }}>
            <h4>Social media Tracking Technologies</h4>
            <p>
              These Tracking Technologies include social media features, such as
              the Facebook "Like" or "Share" buttons, or third party log in
              services. These features are either hosted by a third party or
              hosted directly on our Services. Your interactions with these
              features are governed by the privacy statement of the company
              providing these features.
            </p>
          </li>
        </p>
        <h3>Storing Tracking Technologies</h3>
        <p>
          We store Tracking Technologies when you visit or access our Services.
          These are called "First Party Tracking Technologies". In addition,
          Tracking Technologies are stored by other third parties (for example
          our analytics service providers, business partners and advertisers) –
          these are called "Third Party Tracking Technologies".
        </p>
        <p>
          Both types of Tracking Technologies may be stored either for the
          duration of your visit on our Services or for repeat visits.
        </p>
        <h3>How To Manage Your Cookie Settings</h3>
        <p>
          There are various ways in which you can manage and control your cookie
          settings. Please remember that, by deleting or blocking cookies, some
          of the features of the Services may not work properly or as
          effectively.
        </p>
        <p>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://support.google.com/chrome/answer/95647?hl=en'
              target='_blank'
              rel='noopener noreferrer'
            >
              Google Chrome
            </a>
          </li>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://support.microsoft.com/en-us/topic/description-of-cookies-ad01aa7e-66c9-8ab2-7898-6652c100999d'
              target='_blank'
              rel='noopener noreferrer'
            >
              Internet Explorer
            </a>
          </li>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer'
              target='_blank'
              rel='noopener noreferrer'
            >
              Mozilla Firefox
            </a>
          </li>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://support.apple.com/en-ge/guide/safari/sfri11471/mac'
              target='_blank'
              rel='noopener noreferrer'
            >
              Safari (Desktop)
            </a>
          </li>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://support.apple.com/en-us/HT201265'
              target='_blank'
              rel='noopener noreferrer'
            >
              Safari (Mobile)
            </a>
          </li>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://support.google.com/nexus/answer/54068?visit_id=637249861772874734-2281104728&hl=en&rd=1'
              target='_blank'
              rel='noopener noreferrer'
            >
              Android Browser
            </a>
          </li>
        </p>
        <p>
          For other browsers, please consult the documentation that your browser
          manufacturer provides.
        </p>
        <h3>Turning off third party cookies</h3>
        <p>
          You can turn off certain third party targeting and advertising cookies
          by visiting the Network Advertising Initiative webpage.
        </p>
        <h3>Other helpful resources</h3>
        <p>
          To learn more about advertisers’ use of cookies the following links
          may be helpful:
        </p>
        <p>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://www.youronlinechoices.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              European Interactive Digital Advertising Alliance (EU)
            </a>
          </li>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://www.iab.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Internet Advertising Bureau (US)
            </a>
          </li>
          <li style={{ marginLeft: '30px' }}>
            <a
              href='https://iabeurope.eu/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Internet Advertising Bureau (EU)
            </a>
          </li>
        </p>
        <p>
          EU residents may also use our Dedicated Cookie Preferences Control
          Menu, which can also be accessed via the “Consent Preferences” link
          located on our homepage. Most vendors used on our site could be
          managed using that menu. However, due to technical factors outside of
          our control, other vendors' cookies could be managed by using any of
          the options described above.
        </p>
        <p>
          California consumers may also use our dedicated “Do Not Sell My Data”
          link located on our homepage.
        </p>
        <h3>
          Additional Information - What Other Types Of Tracking Technologies Are
          Used On Our Service?
        </h3>
        <p>
          <h4>
            Strictly necessary; First party Tracking Technology; Strictly
            necessary Tracking Technologies
          </h4>
          <p>
            These unlisted Tracking Technologies might be in use on internal
            sections of the Services, in order to customize and simplify the
            user experience on the site by remembering choices you made and your
            log in credentials.
          </p>
          <h4>
            Google Analytics; Third party Tracking Technology; Performance
            Tracking Technologies
          </h4>
          <p>
            This cookie is used to collect information about how visitors use
            our Service. We use the information to compile reports and to help
            us improve the Service. The cookies collect information in an
            anonymous form, including the number of visitors to the Service,
            where visitors have come to the Service from and the pages they
            visited. You can read more on{' '}
            <span>
              <a
                href='https://policies.google.com/privacy'
                target='_blank'
                rel='noopener noreferrer'
              >
                Google's Privacy Policy
              </a>
            </span>
          </p>
          <h4>
            Microsoft Clarity; Third party Tracking Technology; Performance
            Tracking Technologies
          </h4>
          <p>
            <span>
              <a
                href='https://policies.google.com/privacy'
                target='_blank'
                rel='noopener noreferrer'
              >
                Microsoft Clarity
              </a>
            </span>{' '}
            is used in some territories to collect information about how
            visitors use our Service. We use the information to replay user
            sessions and explore heatmaps, in order to compile reports and to
            help us improve the Service. The collected information may be
            Personal Data used by Microsoft for its advertising purposes.
            Clarity assigns a unique user ID to each user, and any information
            associated with that user ID may be Personal Data. Microsoft may
            also collect statistical data. You can read more on{' '}
            <a
              href='https://privacy.microsoft.com/en-us/privacystatement'
              target='_blank'
              rel='noopener noreferrer'
            >
              Microsoft's Privacy Statement.
            </a>
          </p>
          <h4>
            Social Media/Embeds; Third party Tracking Technology; Social Media
            Tracking Technologies
          </h4>
          <p>
            Our website includes features such as "like" and "share" buttons on
            social networks and also third party embedded codes (for example:
            Facebook, Twitter, and Instagram). All these features are provided
            by vendors and platforms who use their own cookies to allow you to
            utilize their services, to measure your interaction with them, and
            to recognize you after being connected to your account on their
            services. For more information, please visit each such platform's
            privacy policy as applicable:{' '}
            <a
              href='https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0'
              target='_blank'
              rel='noopener noreferrer'
            >
              Facebook's Privacy Policy
            </a>
            ,{' '}
            <a
              href='https://twitter.com/en/privacy'
              target='_blank'
              rel='noopener noreferrer'
            >
              Twitter's Privacy Policy
            </a>
            ,{' '}
            <a
              href='https://help.instagram.com/155833707900388'
              target='_blank'
              rel='noopener noreferrer'
            >
              Instagram's Privacy Policy.
            </a>
          </p>
          <p>
            • The aforementioned Tracking Technologies are additional to the
            tracking technologies managed via our dedicated cookie preferences
            control menu, as described above.
          </p>
        </p>
      </Main>
    </Stack>
  </Layout>
)

export default CookiePolicy
